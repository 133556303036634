var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h2',{staticClass:"text-blue font-weight-bold mt-1",domProps:{"innerHTML":_vm._s(_vm.$t('currency'))}})]),_c('b-card',{staticClass:"row-height"},[_c('b-row',{attrs:{"cols":"12"}},[_c('b-col',{staticClass:"mb-2 col-md-12"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){_vm.addCurrencyModal = true}}},[_vm._v(_vm._s(_vm.$t('add_new_currency'))+" ")])],1),_c('b-table',{staticClass:"position-relative",attrs:{"fields":_vm.fields,"items":_vm.currencies,"hover":"","responsive":"","striped":""}})],1)],1),_c('b-modal',{staticStyle:{"padding-top":"10vh"},attrs:{"hide-header-close":"","no-close-on-backdrop":"","scrollable":"","size":"md"},on:{"ok":function($event){return _vm.createCurrency()}},model:{value:(_vm.addCurrencyModal),callback:function ($$v) {_vm.addCurrencyModal=$$v},expression:"addCurrencyModal"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form-group',{attrs:{"label":_vm.$t('name') + ':',"id":"currencyName","label-for":"currency_name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"currency_name"},model:{value:(_vm.currencyData.name),callback:function ($$v) {_vm.$set(_vm.currencyData, "name", $$v)},expression:"currencyData.name"}})],1),_c('span',{staticClass:"text-danger"})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('code') + ':',"id":"currencyCode","label-for":"currency_code"}},[_c('validation-provider',{attrs:{"name":_vm.$t('code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"currency_code"},model:{value:(_vm.currencyData.code),callback:function ($$v) {_vm.$set(_vm.currencyData, "code", $$v)},expression:"currencyData.code"}})],1),_c('span',{staticClass:"text-danger"})]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('sign') + ':',"id":"currencySign","label-for":"currency_sign"}},[_c('validation-provider',{attrs:{"name":_vm.$t('sign'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"currency_sign"},model:{value:(_vm.currencyData.sign),callback:function ($$v) {_vm.$set(_vm.currencyData, "sign", $$v)},expression:"currencyData.sign"}})],1),_c('span',{staticClass:"text-danger"})]}}],null,true)})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }